import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Navbar from "../components/navbar"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Header from "../components/header"
import Head from "../components/head"
import Button from "../components/button"
import SubHeaderHome from "../components/subHeaderHome"
import image1 from "../assets/images/enterprise/1.jpeg"
import image2 from "../assets/images/enterprise/2.jpeg"
import image3 from "../assets/images/enterprise/3.jpg"
import business from "../assets/images/enterprise/business.jpg"
import technology from "../assets/images/enterprise/technology.jpg"
import support from "../assets/images/enterprise/support-mockup-alt.jpg"
import pdf from "../assets/files/jetti-support.pdf"


export default function Enterprise() {

    return (
      <Layout navbar="transparent">
        <Head title="Composable tech engineered for Enterprise-level requirements" description="Flexible architecture engineered for Enterprise-level requirements."/>
        <BackgroundShape style={{shape: "clip-fixed", color: "bg-enterprise-sm", particles: false }}/>
        <Container style={{classes: "container position-relative" }}>
          <Header content={{title: "Future-proof your business growth with Onport's Enterprise Solutions", text: "Technology is at the core of a successful Marketplace. Onport's expertise in Marketplaces has allowed us to deliver a comprehensive and robust solution for modern, Enterprise systems"}} style={{color: "text-center"}}/>
          <Button style={{ href:`/contacts/enterprise`, text: "Contact us", shape: "btn-contact btn-contact__light", active:""}}/>
        </Container>
        <Container style={{classes: "container py-5" }}>
          <SubHeaderHome content={{title: "Flexible components to meet diverse Enterprise requirements.", text: "Our technology is prepared to help your business scale, offering robust and secure APIs to connect to your existing environments, create complex user flows and speed-up your time to market."}} style={{color: "my-5"}}/>
            <div className="row align-items-center">
                <div className="col-12 col-md-6">
                    <div className="d-flex">
                        <div>
                            <div className="my-5 mr-3 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="0">
                            <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Enterprise SLA’s</p>
                            </div>
                            <div className="my-5 mr-3 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="100">
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Staging Environments for API and Integration Testing</p>
                            </div>
                            <div className="my-5 mr-3 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="200">
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Headless REST API architecture</p>
                            </div>
                        </div>
                        <div>
                            <div className="my-5 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="0">
                            <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Enterprise Grade cloud & support infrastructure</p>
                            </div>
                            <div className="my-5 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="100">
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Auditing, Change Logs and Role Based</p>
                            </div>
                            <div className="my-5 col-12 col-md-4 d-flex text-center align-items-center technology-benefit" style={{borderRadius:'8px', background: "#2B4161", maxWidth: "260px", height: "130px", flexGrow: "0"}} data-sal="slide-down" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="200">
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "500"}}>Dedicated developer support</p>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="col-12 col-md-6">
                    <div className="text-center p-4 d-flex flex-column justify-content-between" style={{borderRadius:'8px', background: "#2B4161", width: "500px", height: "230px", flexGrow: "0"}} data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600">
                        <p className="mx-auto" style={{color: "white", fontSize: "2rem", fontWeight: "300"}}>Developer Portal</p>
                            <div className="developers-portal h-75 w-100">
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Real-Time API Logs</p>
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Channels Management</p>
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Webhooks</p>
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>API Versioning</p>
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Onport API Documentation</p>
                                <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Support Portal</p>
                            </div>
                    </div>
                    <div className="mt-4 text-center p-4 d-flex flex-column justify-content-between" style={{borderRadius:'8px', background: "#2B4161", width: "500px", height: "230px", flexGrow: "0"}} data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600">
                         <p className="mx-auto" style={{color: "white", fontSize: "2rem", fontWeight: "300"}}>Developer Tools</p>
                        <div className="developers-tools h-75 w-100">
                            <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Command-Line Interface</p>
                            <p className="mx-auto" style={{color: "white", fontSize: "1rem", width: "80%", fontWeight: "300"}}>Onport SDK</p>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <Container style={{classes: "container-fluid dark-blue"}}>
            <Container style={{classes: "container text-center py-5 mt-5" }}>
                <p className="py-5" style={{ color: "white", fontWeight: "300", fontSize: "1.4rem"}} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Onport Platform Architecture Performance</p>
                <p className="pb-5 container" style={{ color: "rgba(255,255,255,0.7)", fontWeight: "300", fontSize: "1.2rem"}} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Our state of the art technology and infrastructure, allows us to handle millions of API calls everyday, with no performance issues, and fully prepared to scale.</p>
                <div>
                <div className="row pb-5">
                <div className="col-12 col-md-6 text-center" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
                    <p style={{ color: "white", fontWeight: "700", fontSize: "3rem"}}>100M+</p>
                    <p style={{ color: "white", fontWeight: "300", fontSize: "1.2rem"}}>Api calls/day</p>
                    <p style={{ color: "white", fontWeight: "700", fontSize: "3rem"}}>500M+</p>
                    <p style={{ color: "white", fontWeight: "300", fontSize: "1.2rem"}}>Inventory updates</p>
                </div>
                <div className="col-12 col-md-6 text-center" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
                    <p style={{ color: "white", fontWeight: "700", fontSize: "3rem"}}>50M+</p>
                    <p style={{ color: "white", fontWeight: "300", fontSize: "1.2rem"}}>Revenue/year</p>
                    <p style={{ color: "white", fontWeight: "700", fontSize: "3rem"}}>1M+</p>
                    <p style={{ color: "white", fontWeight: "300", fontSize: "1.2rem"}}>Orders/year</p>
                </div>
                </div>
                <p className="py-5" style={{ color: "white", fontWeight: "300", fontSize: "1.4rem"}} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Designed to support complex Enterprise operations</p>
                </div>
            </Container>
        </Container>

        <div style={{background: "#f7f7f7"}}>
          <Container style={{classes: "banner-container container mb-5" }}>
            <div className="row justify-content-around">
              <div className="col-12 col-md-5 text-center">
                <img src={require("../assets/icons/enterprise/dashboard.png")} alt="" style={{height: "140px", width: "140px", margin: "0 auto 0 auto"}} data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600"/>
                <p className="feature-highlight__title" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Marketplace automation</p>
                <p className="feature-highlight__description" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Your marketplace operations have never been as seamless with automated importing of your vendor products, syncing information and inventory levels, automated order routing, payments, and more.</p>
              </div>
              <div className="col-12 col-md-5 text-center">
                <img src={require("../assets/icons/enterprise/api.png")} alt="" style={{height: "140px", width: "140px", margin: "0 auto 0 auto"}} data-sal="slide-right" data-sal-easing="ease" data-sal-duration="600"/>
                <p className="feature-highlight__title" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Blended fulfillment</p>
                <p className="feature-highlight__description" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Fulfil you in-house and dropship order seamlessly with the use of our solutions, ensuring your customers always receive their orders on time!</p>
              </div>
            </div>
            <div className="row justify-content-around">
              <div className="col-12 col-md-5 text-center">
                <img src={require("../assets/icons/enterprise/vendor.png")} alt="" style={{height: "140px", width: "140px", margin: "0 auto 0 auto"}} data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600"/>
                <p className="feature-highlight__title" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">B2B wholesale</p>
                <p className="feature-highlight__description" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">By taking advantage of our SaaS solutions, you can digitize your B2B wholesale operations overnight. Whether you are looking to automate dropshipping, publishing your products via resellers, or streamlining customer returns - Onport has a solution for you.</p>
              </div>
              <div className="col-12 col-md-5 text-center">
                <img src={require("../assets/icons/enterprise/returns.png")} alt="" style={{height: "140px", width: "140px", margin: "0 auto 0 auto"}} data-sal="slide-right" data-sal-easing="ease" data-sal-duration="600"/>
                <p className="feature-highlight__title" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Multi-destinations returns</p>
                <p className="feature-highlight__description" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Use our flexible returns logic to facilitate your returns process, by having the ability to return items both to your in-house warehouses, or directly to your vendors.</p>
              </div>
            </div>

          </Container>
          <div className="text-center mx-auto py-5">
                <Button style={{ href:`/contacts/enterprise`, text: "Talk to sales", shape: "btn-contact position-static mb-5", active:""}}/>
            </div>
        </div>
      </Layout>
    )
}
